.main-page .saved-category {
    margin-top: 16px;
}

.main-page {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(90vh - 90px);
}

.about-page-link {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    align-items: center;
    text-decoration: none;
    color: var(--text-primary);
    font-size: 17px;
    line-height: 22px;
}