.about-page {
    position: relative;
    height: calc(100vh - 30px);
}

.about-content {
    width: 100%;
    padding-top: 30px;
}

.about-header {
    text-align: center;
    margin-bottom: 134px;
}

.about-header img {
    max-width: 168px;
    height: auto;
    box-shadow: 0px 12px 20px 0px #0000000F;
    border-radius: 30px;
    border: 1px solid #00000012;
}

.about-header h1 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 590;
    letter-spacing: -1px;
    margin-top: 24px;
}

.about-header p {
    margin: 0;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: var(--text-secondary);
}


.about-contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.about-contact-item p {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.4px;
    margin: 0;
}

.about-contact-link {
    border: 1px solid var(--border-primary);
    padding: 10px 14px 10px 14px;
    border-radius: 30px;
    color: var(--text-primary);
    text-decoration: none;
    font-size: 15px;
    font-weight: 590;
    line-height: 20px;
    margin-left: 12px;
}