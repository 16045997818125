.browser-launch-page {
    max-width: 250px;
    margin: 0 auto;
    text-align: center;
}


.browser-launch-page .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 223px;
}

.browser-launch-page .logo {
    max-width: 100%;
    height: auto;
}


.browser-launch-page .app-screen {
    display: block;
    width: 250px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.browser-launch-page p,
.browser-launch-page h1 {
    max-width: 203px;
}

.browser-launch-page h1 {
    font-weight: 590;
    font-size: 20px;
    letter-spacing: -0.45px;
    margin: 16px auto 8px auto;
}

.browser-launch-page p {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.08px;
    margin: 0 auto 26px auto;
}


.browser-launch-page a {
    display: block;
    background-color: #007AFF;
    width: 100%;
    color: #fff;
    padding: 14px 0 14px 0;
    border-radius: 16px;
    text-decoration: none;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4px;
}
