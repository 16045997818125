.saved-page header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.saved-page header h1 {
    font-size: 28px;
    font-weight: 590;
    line-height: 34px;
    margin: 0;
}

.saved-page header {
    margin-bottom: 24px;
}

.saved-page .category-section {
    margin-bottom: 24px;
}
