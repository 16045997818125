.error {
    text-align: center;
    padding-top: 60px;
}

.error h1 {
    color: var(--text-primary);
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.45px;
    font-weight: 590;
    margin: 8px 0;
}

.error p {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.4px;
    max-width: 335px;
    margin: 0 auto;
    margin-top: 0;
    margin-bottom: 16px;
}