.feedback-headline {
    font-size: 24px;
    font-weight: 590;
    line-height: 28px;
    letter-spacing: 0.07px;
    margin: 0;
}

.feedback-description {
    font-size: 17px;
    letter-spacing: -0.4px;
    line-height: 22px;

    margin: 8px 0 20px 0;
}

.feedback-textarea {
    min-height: 80px;
    width: 100%;
    outline: none;
    border-radius: 18px;
    padding: 10px 16px;
    resize: none;
    background-color: var(--surface-secondary);
    border: 0;
    color: var(--text-primary);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.4px;
}

.feedback-error {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.08px;
    color: var(--text-secondary);
}