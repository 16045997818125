.loader {
    width: 100vw;
    height: 100vh;
}

.loader .loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
}

.loader svg path {
    fill: var(--icon-primary);
}