/* Slider */
.content-slider {
    background: var(--background-secondary);
    margin: 0 -15px;
    padding: 10px 15px;
}

.content-slider .slick-list {
    transition: .2s ease-in-out;
}
.content-item {
    padding-bottom: 30px;
}


.content-slider .slick-dots {
    background: var(--surface-tertiary);
    bottom: 0;
    width: auto;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 20px;
    padding: 0 5px;
    height: 24px;
    align-items: center;
}

.content-slider .slick-dots li {
    width: 8px;
    height: 8px;
}

.content-slider .slick-dots li button::before {
    content: none;
}


.content-slider .slick-dots li:hover button,
.content-slider .slick-dots li.slick-active button {
    background-color: var(--icon-primary);
}

.content-slider .slick-dots li button {
    background-color: var(--icon-disabled);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
}


.content-slider-header {
    font-size: 20px;
    line-height: 25px;
    font-weight: 590;
    margin-bottom: 10px;
}


/* Content */
.content-item {
    font-size: 17px;
    letter-spacing: -0.4px;
}

.content-item ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.content-item img {
    max-width: 100%;
    width: 100%;
    display: block;
    border-radius: 18px;
    margin-bottom: 12px;
}

.content-item h3 {
    color: var(--text-secondary);
    font-size: 17px;
    line-height: 22px;
    font-weight: 590;
    margin-top: 0;
    margin-bottom: 0;
}

.content-item h4 {
    font-size: 17px;
    line-height: 22px;
    font-weight: 590;
}