
.exercise-tags {
    position: relative;
    background: var(--surface-secondary);
    gap: 8px;
    border-radius: 8px 8px 18px 18px;
    margin-bottom: 24px;
    padding: 8px;
    overflow: hidden;
}



.exercise-tags h2 {
    font-weight: 590;
}
.exercise-tags ul, 
.exercise-tags li {
    display: inline-block;
}


.exercise-tags ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    margin-right: -8px;
    margin-left: -8px;
    padding-left: 8px;
    padding-right: 8px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}


.exercise-tags ul::-webkit-scrollbar {
    display: none;
}

.exercise-tags li {
    background: var(--surface-tertiary);
    border-radius: 10px;
    padding: 6px 10px;
    margin: 2px;
    font-size: 16px;
    text-align: center;
    flex-shrink: 0;
}


.exercise-tags:after,
.exercise-tags:before {
    position: absolute;
    content: '';
    display: block;
    opacity: 0;
    width: 40px;
    height: 48px;
    bottom: 0;
    transition: .1s ease-in-out;
}



.exercise-tags--fade-right:after,
.exercise-tags--fade-left:before {
    opacity: 1;
}

.exercise-tags--fade-right:after {
    right: 0;
    background: linear-gradient(270deg, #F2F3F5 0%, rgba(242, 243, 245, 0) 100%);
}

.exercise-tags--fade-left:before {
    left: 0;
    background: linear-gradient(270deg, rgba(162, 163, 164, 0) 0%, #F2F3F5 100%);
}


/* TODO: Think about another way to customize color */
.app.dark-theme  .exercise-tags--fade-right:after {
    background: linear-gradient(270deg, #222222 0%, rgba(34, 34, 34, 0) 100%);
}

.app.dark-theme  .exercise-tags--fade-left:before {
    background: linear-gradient(270deg, rgba(34, 34, 34, 0) 0%, #222222 100%);
}