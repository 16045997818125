.subscription {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    transition: .3s ease-in-out;
    padding-bottom: 20px;
}


.subscription.enabled .subscription-link {
    max-width: 91px;
    padding: 8px;
}

.subscription-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 197px;
    margin: 0 auto;
    background-color: var(--surface-secondary);
    padding: 8px 16px 8px 8px;
    border-radius: 50px;
    text-decoration: none;
    color: var(--text-primary);
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.45px;
    cursor: pointer;
}

.subscription-link p {
    margin: 0;
    padding: 0;
}

.stars-price {
    display: flex;
    align-items: center;
}

.stars-price span {
    font-weight: 590;
    position: relative;
    top: 2px;
}

.subscription-descr {
    max-width: 197px;
    margin: 8px auto 0 auto;
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.08px;
    color: var(--text-secondary);
}