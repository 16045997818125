.exercise-item {
    display: flex;
    text-decoration: none;
    color: var(--text-primary);
    background: var(--surface-primary);
    padding: 8px;
    border-radius: 20px;
    height: 116px;
}

.exercise-item h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 590;
    line-height: 25px;
    letter-spacing: -0.45px;
}

.exercise-item p {
    font-size: 13px;
    letter-spacing: -0.08px;
    height: 42px;
    overflow: hidden;
    max-width: 203px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}


.exercise-item--big-description p {
    -webkit-line-clamp: 3;
    height: 60px;
}

.exercise-item-content {
    margin-left: 12px;
}

.exercise-item-img {
    width: 100px;
    height: 100px;
    min-width: 100px;
    overflow: hidden;
    border-radius: 12px;
}

.exercise-item-img img {
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}