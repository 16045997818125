.category-section h2 {
    font-size: 20px;
    font-weight: 590;
    line-height: 25px;
    margin-bottom: 8px;
}


.category-section-exercise {
    margin-bottom: 20px;
}

.category-section-exercise:last-child {
    margin-bottom: 0;
}