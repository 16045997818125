.button {
    outline: none;
    border: 0;
    font-size: 15px;
    font-weight: 590;
    line-height: 20px;
}

.button.primary,
.button.primary:hover {
    letter-spacing: -0.23px;
    color: #007AFF;
    background: #4378FF1A;
    border-radius: 20px;
    padding: 8px 12px;
}

.button.secondary {
    background: var(--surface-secondary);
    color: var(--text-primary);
    padding: 12px 15px;
    height: 50px;
    width: 100%;
    border-radius: 10px;
}

.button.tertiary {
    height: 50px;
    width: 100%;
    border-radius: 10px;
    outline: none;
    border: 0;
    cursor: pointer;
    background-color: var(--icon-primary);
    color: var(--text-primary-inverse);
    font-weight: 590;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.4px;
}