.category-page .exercise-item {
    margin-bottom: 15px;
}

.category-page h1 {
    font-size: 28px;
    font-weight: 590;
    line-height: 34px;
}

.category-page header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.category-page .search-info {
    margin-bottom: 10px;
}


.not-found-exercises {
    text-align: center;
    padding-top: 32px;
}

.not-found-exercises h2 {
    font-size: 20px;
    font-weight: 590;
    line-height: 25px;
    letter-spacing: -0.45px;
    margin-bottom: 8px;
}

.not-found-exercises p {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.4px;
    margin-top: 0;
    margin-bottom: 16px;
}

.category-exercises {
    margin-bottom: 30px;
}

.feedback-link {
    font-size: 15px;
    line-height: 20px;
    display: inline-block;
    padding: 8px 12px;
    background: #4378FF1A;
    border-radius: 20px;
    text-decoration: none;
}