

/* Tokens */
/* palette */
:root {
  /* Mode 1 */
  /* color */
  --blue-blue-100: #b0d6ff;
  --blue-blue-200: #8ac2ff;
  --blue-blue-300: #54a6ff;
  --blue-blue-400: #3395ff;
  --blue-blue-50: #e6f2ff;
  --blue-blue-500: #007aff;
  --blue-blue-600: #006fe8;
  --blue-blue-700: #0057b5;
  --blue-blue-800: #00438c;
  --blue-blue-900: #00336b;
  --green-green-100: #b1edce;
  --green-green-200: #8be4b6;
  --green-green-300: #55d894;
  --green-green-400: #35d180;
  --green-green-50: #e6f9ef;
  --green-green-500: #02c560;
  --green-green-600: #02b357;
  --green-green-700: #018c44;
  --green-green-800: #016c35;
  --green-green-900: #00350a;
  --neutral-neutral-100: #fbfbfc;
  --neutral-neutral-1000: #000000;
  --neutral-neutral-200: #f9f9fa;
  --neutral-neutral-300: #f6f7f8;
  --neutral-neutral-400: #f5f5f7;
  --neutral-neutral-50: #fefefe;
  --neutral-neutral-500: #f2f3f5;
  --neutral-neutral-600: #dcdddf;
  --neutral-neutral-700: #acadae;
  --neutral-neutral-800: #858687;
  --neutral-neutral-900: #666667;
  --neutral-neutral-925: #363636;
  --neutral-neutral-950: #222222;
  --neutral-neutral-w: #ffffff;
  --orange-orange-100: #eed1b0;
  --orange-orange-200: #e5ba8a;
  --orange-orange-300: #d99b54;
  --orange-orange-400: #d28833;
  --orange-orange-50: #f9f0e6;
  --orange-orange-500: #c76a00;
  --orange-orange-600: #b56000;
  --orange-orange-700: #8d4b00;
  --orange-orange-800: #6d3a00;
  --orange-orange-900: #542d00;
  --purple-purple-100: #e0b0ee;
  --purple-purple-200: #d18ae5;
  --purple-purple-300: #bd54d9;
  --purple-purple-400: #b033d2;
  --purple-purple-50: #f5e6f9;
  --purple-purple-500: #9c00c7;
  --purple-purple-600: #8e00b5;
  --purple-purple-700: #6f008d;
  --purple-purple-800: #56006d;
  --purple-purple-900: #420054;
  --red-red-100: #f8b1b5;
  --red-red-200: #f48c91;
  --red-red-300: #ef575e;
  --red-red-400: #ec363f;
  --red-red-50: #fde6e7;
  --red-red-500: #e7040f;
  --red-red-600: #d2040e;
  --red-red-700: #a4030b;
  --red-red-800: #7f0208;
  --red-red-900: #430000;
}

.app.light-theme {
  --background-primary: var(--neutral-neutral-w);
  --background-secondary: var(--neutral-neutral-500);
  --border-achivement: var(--purple-purple-200);
  --border-disabled: var(--neutral-neutral-400);
  --border-error: var(--red-red-300);
  --border-primary: var(--neutral-neutral-600);
  --border-secondary: var(--neutral-neutral-800);
  --border-success: var(--green-green-500);
  --button-achivement: var(--purple-purple-50);
  --button-disabled: var(--neutral-neutral-600);
  --button-error: var(--red-red-50);
  --button-primary: var(--blue-blue-500);
  --button-primary-active: var(--blue-blue-300);
  --button-primary-inverse: var(--blue-blue-900);
  --button-secondary: var(--blue-blue-50);
  --button-success: var(--green-green-50);
  --icon-achivement: var(--purple-purple-600);
  --icon-disabled: var(--neutral-neutral-600);
  --icon-error: var(--red-red-600);
  --icon-primary: var(--neutral-neutral-1000);
  --icon-primary-inverse: var(--neutral-neutral-w);
  --icon-secondary: var(--neutral-neutral-800);
  --icon-success: var(--green-green-800);
  --icon-tertiary: var(--neutral-neutral-700);
  --icon-static-primary: var(--neutral-neutral-w);
  --icon-static-primary-inverse: var(--neutral-neutral-1000);
  --surface-achivement: var(--purple-purple-50);
  --surface-error: var(--red-red-50);
  --surface-primary: var(--neutral-neutral-300);
  --surface-primary-inverse: var(--neutral-neutral-800);
  --surface-secondary: var(--neutral-neutral-500);
  --surface-tertiary: var(--neutral-neutral-w);
  --surface-success: var(--green-green-50);
  --text-achivement: var(--purple-purple-600);
  --text-disabled: var(--neutral-neutral-600);
  --text-error: var(--red-red-600);
  --text-primary: var(--neutral-neutral-1000);
  --text-primary-inverse: var(--neutral-neutral-w);
  --text-secondary: var(--neutral-neutral-800);
  --text-success: var(--green-green-800);
  --text-tertiary: var(--neutral-neutral-700);
  --text-static-primary: var(--neutral-neutral-w);
  --text-static-primary-inverse: var(--neutral-neutral-1000);
}

.app.dark-theme {
  --background-primary: var(--neutral-neutral-1000);
  --background-secondary: var(--neutral-neutral-950);
  --border-achivement: var(--purple-purple-700);
  --border-disabled: var(--neutral-neutral-950);
  --border-error: var(--red-red-700);
  --border-primary: var(--neutral-neutral-800);
  --border-secondary: var(--neutral-neutral-900);
  --border-success: var(--green-green-700);
  --button-achivement: var(--purple-purple-50);
  --button-disabled: var(--neutral-neutral-600);
  --button-error: var(--red-red-50);
  --button-primary: var(--blue-blue-500);
  --button-primary-active: var(--blue-blue-700);
  --button-primary-inverse: var(--blue-blue-900);
  --button-secondary: var(--blue-blue-50);
  --button-success: var(--green-green-50);
  --icon-achivement: var(--purple-purple-600);
  --icon-disabled: var(--neutral-neutral-900);
  --icon-error: var(--red-red-200);
  --icon-primary: var(--neutral-neutral-w);
  --icon-primary-inverse: var(--neutral-neutral-1000);
  --icon-secondary: var(--neutral-neutral-400);
  --icon-success: var(--green-green-100);
  --icon-tertiary: var(--neutral-neutral-800);
  --icon-static-primary: var(--neutral-neutral-w);
  --icon-static-primary-inverse: var(--neutral-neutral-1000);
  --surface-achivement: var(--purple-purple-900);
  --surface-error: var(--red-red-900);
  --surface-primary: var(--neutral-neutral-950);
  --surface-primary-inverse: var(--neutral-neutral-200);
  --surface-secondary: var(--neutral-neutral-950);
  --surface-tertiary: var(--neutral-neutral-925);
  --surface-success: var(--green-green-900);
  --text-achivement: var(--purple-purple-100);
  --text-disabled: var(--neutral-neutral-800);
  --text-error: var(--red-red-200);
  --text-primary: var(--neutral-neutral-w);
  --text-primary-inverse: var(--neutral-neutral-1000);
  --text-secondary: var(--neutral-neutral-700);
  --text-success: var(--green-green-100);
  --text-tertiary: var(--neutral-neutral-800);
  --text-static-primary: var(--neutral-neutral-w);
  --text-static-primary-inverse: var(--neutral-neutral-1000);
}

.app {
  background: var(--background-primary);
  font-family: 'SF Pro', Arial, sans-serif;
  overflow: hidden;
  padding-top: 15px;
  min-height: 100vh;
  color: var(--text-primary);
}


.app h1, .app h2, .app h3, .app h4 {
  letter-spacing: -0.45px;
}

.app p {
  letter-spacing: -0.08px;
}


.app-content {
  min-height: 90vh;
}

body {
  margin: 0;
}


@media (max-width: 640px) {
  .app.fullscreen .app-content {
    padding-top: 90px;
  }
}

body, html {
  /* override boostrap colors */
  background: var(--background-primary) !important;
}