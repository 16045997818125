.category {
    background: var(--surface-secondary);
    padding: 16px;
    border-radius: 20px;
    display: block;
    text-decoration: none;
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 115px;
    position: relative;
}

.category h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 590;
}

.category-count {
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 590;
    color: var(--text-secondary);
}