

.exercise-article {
    padding-bottom: 80px;
    position: relative;
}

.exercise-article h1 {
    font-size: 28px;
    font-weight: 590;
}


.tags-description {
    color: #ACADAE;
    margin: 0;
    font-size: 13px;
}


.exercise-preview {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 8px;
}
.exercise-preview img {
    max-width: 100%;
}

.exercise-content {
    padding: 24px 0;
}

.exercise-content h2 {
    font-weight: 590;
}

.exercise-content p {
    margin: 0;
    font-weight: 400;
}

.warning-list.good {
    color: var(--text-success);
    background: var(--surface-success);
}

.warning-list.bad {
    color: var(--text-error);
    background: var(--surface-error);
}

.warning-list {
    padding: 14px;
    border-radius: 18px;
    white-space: pre-line;
}

.warning-list h3 {
    font-size: 15px;
    font-weight: 590;
    margin: 0;
    line-height: 20px;
}

.warning-list div {
    line-height: 16px;
    font-size: 13px;
}

.warning-list header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}


.warning-list .warning-header-icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
    height: 20px;
}

.exercise-note {
    padding-top: 30px;
    white-space: pre-line;
}

.exercise-page-warning-list:first-child {
    margin-bottom: 12px;
}

.exercise-content-item-text {
    white-space: pre-line;
}

.delete-button {
    margin-top: 40px;
}
