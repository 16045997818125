.subscription-content {
    height: calc(100vh - 30px - 90px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.subscription-header {
    text-align: center;
}

.subscription-header h1 {
    font-size: 24px;
    font-weight: 590;
    line-height: 28px;
    letter-spacing: 0.07px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.advantages-list {
    background-color: var(--surface-secondary);
    list-style: none;
    text-align: left;
    padding: 22px;
    border-radius: 20px;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.4px;
}

.advantages-list li {
    margin-bottom: 26px;
}

.advantages-list li:last-child {
    margin-bottom: 0;
}

.advantages-list li i {
    margin-right: 8px;
    position: relative;
    top: -2px;
}

.subscription-footer {
    text-align: center;
}

.subscription-footer p {
    color: var(--text-primary);
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.08px;
    padding: 0 16px;
    margin-top: 6px;
    margin-bottom: 0;
}

.subscription-footer p img {
    position: relative;
    top: -2px;
}



/* Subscribed content */
.subscribed-content {
    position: relative;
    height: calc(100vh - 40px - 90px);
    display: flex;
    flex-direction: column;
}
.subscribed-content img {
    max-width: 100%;
}

.subscribed-image {
    margin-right: -15px;
    margin-left: -15px;
    margin-top: -15px;
}

.subscribed-content main {
    padding-top: 40px;
    text-align: center;
}

.subscribed-content main h1 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.07px;
    font-weight: 590;
    margin: 15px 0 8px 0;
}

.subscribed-content main p {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.4px;
    margin: 0;
}

.subscribed-content .to-exercises-link {
    display: block;
}

.subscribed-content footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}