.search-field {
    position: relative;
}

.search-field input {
    width: 100%;
    height: 42px;
    outline: none;
    background-color: var(--surface-secondary);
    color: var(--text-primary);
    border: 0;
    border-radius: 30px;
    font-size: 17px;
    line-height: 22px;
    padding-left: 42px;
}

.search-field-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    display: flex;
}