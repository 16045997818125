.note {
    background: var(--surface-secondary);
    border-radius: 18px;
    padding: 15px;
}

.note-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.note-header h2 {
    font-size: 15px;
    font-weight: 590;
    line-height: 20px;
    margin-left: 10px;
    margin-bottom: 0;
}

.note-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.note-content {
    font-size: 13px;
    line-height: 16px;
}